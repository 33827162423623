import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TechnicalPage from 'App/pages/TechnicalPage/Technical.page';
import SuccessIcon from 'assets/succes-info.svg';
import { MOBILE } from 'constants/css/breakpoints';

import t from '@guestyci/localize/t.macro';

const SuccessUnpaidBookingPage = ({ breakpoint }) => {
  const isMobile = breakpoint === MOBILE;

  return (
    <TechnicalPage
      breakpoint={breakpoint}
      Img={SuccessIcon}
      title={
        isMobile ? t('Thanks! We will be in touch soon to complete your reservation.') : t('Thank you')
      }
      subTitle={
        isMobile
          ? ''
          : t('We received your information and will be in touch soon to complete your reservation.')
      }
      isLogo
    />
  );
};

SuccessUnpaidBookingPage.propTypes = {
  breakpoint: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  breakpoint: state.layout.breakpoint,
});

export default connect(mapStateToProps)(SuccessUnpaidBookingPage);
