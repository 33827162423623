import React from 'react';
import { invoicePageInvoiceIntlId } from 'utils/intlIds';

import Checkbox from '@guestyci/foundation/Checkbox';
import FormField from '@guestyci/foundation/FormField';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro';

const ReuseCheckbox = () => (
  <FormField name="reusePaymentMethod" type="checkbox" style={{ marginLeft: -10 }}>
    <Checkbox>
      <TextField color="default">
        {t('Save payment information to my account for future reservations')}
      </TextField>
    </Checkbox>
  </FormField>
);

export default ReuseCheckbox;
