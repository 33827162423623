import styled from 'styled-components';
import { mobile, tablet } from 'constants/css/breakpoints';

export const PaneWrapper = styled.div`
  height: calc(100% - 260px);
  position: relative;
  margin: 45px 120px 50px 120px;
  @media ${tablet} {
    margin: 80px 60px;
  }
  @media ${mobile} {
    margin: 80px 20px;
  }
`;

export const PaneTag = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 20px 60px 0 rgba(39, 43, 45, 0.2);
  height: 100%;
  max-height: 585px;
  min-height: 585px;
  max-width: 1200px;
  width: 100%;
  margin-top: auto;
  padding-top: 80px;
  padding-bottom: 60px;
  @media ${mobile} {
    max-height: 100%;
  }
`;

export const SubTitle = styled.div`
  max-width: 100%;
`;
