import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { path } from 'ramda';
import Layout from 'App/components/Layout/Layout.component';
import Pane from 'App/components/Pane/Pane.component';
import Footer from 'App/components/Footer/Footer.component';
import MailToString from 'App/components/MailToString/MailToString.component';
import { MOBILE } from 'constants/css/breakpoints';

import t from '@guestyci/localize/t.macro';
import { blue700 } from '@guestyci/foundation/theme/colors';

const TechnicalPage = ({ breakpoint, Img, title, subTitle, bottomText, quotes, isLogo }) => {
  const isMobile = breakpoint === MOBILE;
  const email = path(['meta', 'integration', 'incomingEmail'], quotes);

  return (
    <Layout withSizeTracker isLogo={isLogo}>
      <>
        <Pane Img={Img} title={title} subTitle={subTitle} isMobile={isMobile} bottomText={bottomText} />
        <Footer background={blue700} isMobile={isMobile}>
          <>
            {t('For more offers please contact us')}
            <MailToString email={email} />
          </>
        </Footer>
      </>
    </Layout>
  );
};

TechnicalPage.propTypes = {
  breakpoint: PropTypes.string.isRequired,
  Img: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  bottomText: PropTypes.string,
  quotes: PropTypes.shape({
    meta: PropTypes.shape({
      integration: PropTypes.shape({
        incomingEmail: PropTypes.string,
      }),
    }),
  }),
  isLogo: PropTypes.bool,
};

TechnicalPage.defaultProps = {
  subTitle: '',
  bottomText: '',
  quotes: {},
  isLogo: false,
};

const mapStateToProps = (state) => ({
  quotes: state.quotes,
});

export default connect(mapStateToProps)(TechnicalPage);
