import { geBaseUrl, STRIPE_SETUP_INTENT_URL, STRIPE_SETUP_INTENTS_URL } from 'constants/url.constants';
import { fetchCross } from 'utils/helpers';

import Resource from '@guestyci/agni';

const { api } = Resource.create({
  baseURL: geBaseUrl(),
});

export const getClientSecret = ({ accountId, listingId }) =>
  api.post(`${STRIPE_SETUP_INTENTS_URL}`, { accountId, listingId });
export const getClientSecretV2 = ({ accountId, listingId }) =>
  fetchCross(`${STRIPE_SETUP_INTENT_URL}`, { accountId, listingId });

export const handleCardSetup = ({ clientSecret, stripe, billingDetails }) => {
  const { firstName, lastName, city, country, street, state, zipCode } = billingDetails;

  return stripe.handleCardSetup(clientSecret, {
    payment_method_data: {
      billing_details: {
        name: `${firstName} ${lastName}`,
        address: {
          city,
          country: country.value,
          line1: street,
          state,
          postal_code: zipCode,
        },
      },
    },
  });
};

export const createToken = ({ stripe, billingDetails }) => {
  const { firstName, lastName } = billingDetails;

  return stripe.createToken({ type: 'card', name: `${firstName} ${lastName}` });
};
