import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import ExpiredPage from 'App/pages/Expired/Expired.page';
import SuccessPaidBookingPage from 'App/pages/SuccessPaidBookingPage/SuccessPaidBookingPage.page';
import SuccessUnpaidBookingPage from 'App/pages/SuccessUnpaidBookingPage/SuccessUnpaidBookingPage.page';
import Main from 'App/pages/Main/Main.page';
import Listing from 'App/pages/Listing/Listing.page';
import Invoice from 'App/pages/Invoice/Invoice.page';
import CommitErrorPage from 'App/pages/CommitError/CommitError.page';
import Oops from 'App/components/Oops/Oops.component';
import { useLanguage } from 'contexts/LanguageContext';
import {
  EXPIRED_PAGE,
  INVOICE_PAGE,
  LISTING_PAGE,
  MAIN_PAGE,
  SUCCESS_UNPAID_BOOKING_PAGE,
  SUCCESS_PAID_BOOKING_PAGE,
  COMMIT_ERROR,
} from 'constants/route.constants';

import './App.css';

function App() {
  return (
    <>
      <Switch>
        <Route path={EXPIRED_PAGE} component={ExpiredPage} exact />
        <Route path={SUCCESS_PAID_BOOKING_PAGE} component={SuccessPaidBookingPage} exact />
        <Route path={SUCCESS_UNPAID_BOOKING_PAGE} component={SuccessUnpaidBookingPage} exact />
        <Route path={INVOICE_PAGE} component={Invoice} />
        <Route path={LISTING_PAGE} component={Listing} exact />
        <Route path={COMMIT_ERROR} component={CommitErrorPage} />
        <Route path={MAIN_PAGE} component={Main} />
      </Switch>
      <Oops />
    </>
  );
}

export default App;
