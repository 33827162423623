import React, { createContext, useContext, useState } from 'react';

const LanguageContext = createContext();

const supportedLanguages = ['en', 'fr', 'de', 'it', 'pl', 'pt', 'es'];

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    const savedLang = localStorage.getItem('lang');
    if (savedLang && supportedLanguages.includes(savedLang)) {
      return savedLang;
    }

    const browserLang = navigator.language.split('-')[0]; // Get primary language code
    return supportedLanguages.includes(browserLang) ? browserLang : 'en';
  });

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage: (lang) => {
          setLanguage(lang);
          localStorage.setItem('lang', lang);
        },
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
