import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Portal from 'App/components/Portal/Portal.component';
import Pane from 'App/components/Pane/Pane.component';
import Layout from 'App/components/Layout/Layout.component';
import { MOBILE } from 'constants/css/breakpoints';
import OopsImg from 'assets/oops.svg';

import t from '@guestyci/localize/t.macro';

const Oops = ({ error = false, errorMessage, isMobile }) =>
  error && (
    <Portal>
      <Layout className="w-100 h-100 pos-absolute top-0 left-0 zindex-10">
        <Pane
          Img={OopsImg}
          isMobile={isMobile}
          subTitle={
            errorMessage
              ? t('Please select another property or contact us for new offer')
              : t('Please refresh the page')
          }
          title={
            errorMessage
              ? t('Oops, the selected property is not available anymore on the selected dates')
              : t('Oops, something went wrong')
          }
        />
      </Layout>
    </Portal>
  );

Oops.propTypes = {
  error: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isMobile: state.layout.breakpoint === MOBILE,
  error: state.layout.error,
  errorMessage: state.layout.errorMessage,
});

export default connect(mapStateToProps)(Oops);
