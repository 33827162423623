import { mobile } from 'constants/css/breakpoints';

import createStyles from '@guestyci/foundation/createStyles';

export const useClasses = createStyles(({ spacer }) => ({
  text: {
    marginTop: `${spacer(6)}px`,
    marginBottom: `${spacer(3)}px`,

    [`@media ${mobile}`]: {
      marginTop: `${spacer(3)}px`,
      marginBottom: 0,
    },
  },
  title: {
    marginTop: `${spacer(3)}px`,

    [`@media ${mobile}`]: {
      marginTop: 0,
    },
  },
}));
