import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TechnicalPage from 'App/pages/TechnicalPage/Technical.page';
import SuccessIcon from 'assets/success.svg';
import { MOBILE } from 'constants/css/breakpoints';

import t from '@guestyci/localize/t.macro';

const SuccessPaidBookingPage = ({ breakpoint }) => {
  const isMobile = breakpoint === MOBILE;
  const title = t('Thank you');
  const subTitle = t('for booking with us!');
  const bottomText = t('You will receive an email confirmation shortly');

  return (
    <TechnicalPage
      breakpoint={breakpoint}
      Img={SuccessIcon}
      title={isMobile ? title : `${title} ${subTitle}`}
      subTitle={isMobile ? subTitle : ''}
      bottomText={bottomText}
    />
  );
};

SuccessPaidBookingPage.propTypes = {
  breakpoint: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  breakpoint: state.layout.breakpoint,
});

export default connect(mapStateToProps)(SuccessPaidBookingPage);
