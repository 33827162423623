import React from 'react';
import { useSelector } from 'react-redux';
import { selectQuoteIntegration } from 'redux/selectors/quoteSelector';
import Pane from 'App/components/Pane/Pane.component';

import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';
import t from '@guestyci/localize/t.macro';

import Error from '../../../assets/error.svg';
import Layout from '../../components/Layout/new/Layout.component';

const useClasses = createStyles(({ spacer }) => ({
  footer: {
    textAlign: 'center',
    backgroundColor: '#2053F8',
    padding: `${spacer(3)}px 0`,
  },
  container: {
    padding: `${spacer(25)}px`,
  },
}));

function CommitError() {
  const classes = useClasses();

  const integration = useSelector(selectQuoteIntegration);

  return (
    <Layout multipleBackground>
      <Pane
        Img={Error}
        subTitle={
          <p>
            <br />
            <br />
            {t("Sorry, we're experiencing an internal server problem.")}
            <br />
            {t('Please try again. If the problem continues, contact us at {email}', {
              email: integration?.incomingEmail,
            })}
          </p>
        }
        title={t("We couldn't confirm your reservation")}
      />

      <Layout.Footer fixed className={classes.footer}>
        <Layout.Container>
          <TextField color="white">
            {t('Have a question? Please contact us at {email}', { email: integration?.incomingEmail })}
          </TextField>
        </Layout.Container>
      </Layout.Footer>
    </Layout>
  );
}

export default CommitError;
