import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import actions from 'redux/actions';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { selectAccountId, selectListingIds, selectQuotes } from 'redux/selectors/quoteSelector';
import { LanguageProvider, useLanguage } from 'contexts/LanguageContext';

import FeatureToggleProvider from '@guestyci/feature-toggle-fe/FeatureToggleProvider';
import LocalizationProvider from '@guestyci/localize/LocalizationProvider';
import { Spinner } from '@guestyci/arc';

const env = process.env.REACT_APP_CLUSTER;
const { FETCH_QUOTES_REQUEST, FETCH_BATCH_LISTINGS_REQUEST } = actions;
const queryClient = new QueryClient();

function OnBoardingContent({ children, match }) {
  const dispatch = useDispatch();
  const quotes = useSelector(selectQuotes);
  const accountId = useSelector(selectAccountId);
  const listingIds = useSelector(selectListingIds);
  const { quotesId } = match.params;
  const isPageReady = !_.isEmpty(quotes) && quotesId;

  useEffect(() => {
    if (quotesId) {
      dispatch({ type: FETCH_QUOTES_REQUEST, payload: { id: quotesId } });
    }
  }, [dispatch, quotesId]);

  useEffect(() => {
    if (_.isEmpty(listingIds)) {
      return;
    }

    dispatch({
      type: FETCH_BATCH_LISTINGS_REQUEST,
      payload: { quotesId, listingIds },
    });
  }, [dispatch, listingIds, quotesId]);

  const { language } = useLanguage();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (language === 'en') {
      setTimeout(() => setLoading(false), 0);
    }
  }, [language]);

  if (!isPageReady) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <FeatureToggleProvider accountId={accountId} env={env}>
        <LocalizationProvider
          language={language}
          onLoad={() => {
            setLoading(false);
          }}
        >
          {loading ? (
            <div className="arc-scope">
              <Spinner
                size="xl"
                className="gst-absolute gst-top-[50%] gst-left-[50%] gst-translate-x-1/2 gst-translate-y-1/2"
              />
            </div>
          ) : (
            children
          )}
        </LocalizationProvider>
      </FeatureToggleProvider>
    </QueryClientProvider>
  );
}

function OnBoarding(props) {
  return (
    <LanguageProvider>
      <OnBoardingContent {...props} />
    </LanguageProvider>
  );
}

export default OnBoarding;
