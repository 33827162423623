import cn from 'classnames';
import { Amenity } from 'App/pages/Listing/components/Listing/Listing.styled';
import React from 'react';
import allAmenities from 'App/pages/Listing/assets/amenities';

import { Divider, Icon } from '@guestyci/atomic-design/dist/v2/components';

const amenitiesObj = {};
allAmenities.forEach((amenity) => {
  amenitiesObj[amenity.lowerCase] = amenity.icon;
});

export const Amenities = ({ amenities, isMobile }) => (
  <div
    className={cn('d-flex flex-wrap justify-content-between', {
      'flex-column': isMobile,
    })}
  >
    {amenities.map((amenity) => (
      <Amenity
        className={cn('d-flex flex-column flex-shrink-0 pb-4', {
          'w-100': isMobile,
        })}
        key={amenity}
      >
        <div className="d-flex align-items-center">
          <Icon width={30} height={30} icon={amenitiesObj[amenity.toLowerCase()]} />
          <div className="font-size-lg text-truncate" title={amenity}>
            {amenity}
          </div>
        </div>
        <Divider className="mt-1" />
      </Amenity>
    ))}
  </div>
);
