import React from 'react';
import PropTypes from 'prop-types';
import { getAdjustmentDisplayValue } from 'utils/helpers';

import { Row } from '@guestyci/foundation';
import TextField from '@guestyci/foundation/TextField';
import Icon from '@guestyci/foundation/legacy/Icon';
import t from '@guestyci/localize/t.macro';

const Promotions = ({ promotions }) => {
  const {
    rule: { discountType, discountAmount, minStayDays, minStayType },
  } = promotions;
  const value = getAdjustmentDisplayValue(discountAmount, discountType);

  return (
    <Row justify="between">
      <TextField className="flex-start-center" color="default">
        <Icon className="mr-1" icon="BtnStarOff" height={14} width={14} />
        <span>{t('{minStayDays} {minStayType} stay promotion', { minStayDays, minStayType })}</span>
      </TextField>
      <TextField color="default">{value}</TextField>
    </Row>
  );
};

Promotions.propTypes = {
  promotions: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    rule: PropTypes.shape({
      discountType: PropTypes.string,
      discountAmount: PropTypes.number,
      minStayDays: PropTypes.number,
      minStayType: PropTypes.string,
    }),
  }),
};

export default Promotions;
