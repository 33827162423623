import { put, call, all } from 'redux-saga/effects';
import { path } from 'ramda';
import _ from 'lodash';
import { createToken, getClientSecret, getClientSecretV2, handleCardSetup } from 'services/stripe';
import {
  COMMIT_PAYMENT_URL,
  COMMIT_WITHOUT_PAYMENT_URL,
  geBaseUrl,
  QUOTES_URL,
  SAND_WORM_URL,
  SEND_QUOTE_URL,
  WITHOUT_PAYMENT_URL,
} from 'constants/url.constants';
import {
  SUCCESS_UNPAID_BOOKING_PAGE,
  SUCCESS_PAID_BOOKING_PAGE,
  SUCCESS_ALL_BOOKING_PAGE,
  COMMIT_ERROR,
  SUCCESS_BOOKING_PAGE,
} from 'constants/route.constants';
import { PAYMENT_METHOD_FAILED_DIALOG } from 'constants/dialogs.constants';
import ActionTypes from 'redux/actions';
import { checkIsV2, fetchCross, parseErrorResponse } from 'utils/helpers';
import { setV2URL } from 'utils/url.utils';

import Resource from '@guestyci/agni';

import { takeOneAndBlock } from './quotes.saga';

const {
  FETCH_INVOICE_DATA_REQUEST,
  FETCH_LISTING_REQUEST,
  FETCH_QUOTES_FAILURE,
  FETCH_QUOTES_SUCCESS,
  PROCESS_INVOICE_FAILURE,
  PROCESS_INVOICE_REQUEST,
  PROCESS_INVOICE_INPROGRESS,
  SHOW_DIALOG,
  UPDATE_GUEST_INVOICE_REQUEST,
} = ActionTypes;

const { api } = Resource.create({
  baseURL: geBaseUrl(),
});

export function* getInvoiceData(action) {
  try {
    const quotesId = path(['payload', 'quotesId'], action);
    const listingId = path(['payload', 'listingId'], action);

    const { data } = yield call(
      checkIsV2() ? fetchCross : api.get,
      `${checkIsV2() ? SEND_QUOTE_URL : QUOTES_URL}/${quotesId}`,
    );

    yield put({ type: FETCH_QUOTES_SUCCESS, payload: data });
    yield put({
      type: FETCH_LISTING_REQUEST,
      payload: { quotesId, listingId },
    });
  } catch (error) {
    yield put({ type: FETCH_QUOTES_FAILURE });
  }
}

export function* processBooking(action) {
  const {
    accountId,
    data: {
      reservation,
      booker,
      guests,
      providerType,
      inquiryData,
      isPaymentRequired,
      conversationId,
      isRequestToBook,
      ratePlanIdsByReservationId,
    },
    history,
    paymentProviderId,
    quotesId,
    stripe,
    billingDetails,
    reusePaymentMethod,
    submitForm,
    quotes,
    listingId,
  } = action.payload;
  const isSingleQuote = !!reservation;
  const data = {};
  if (paymentProviderId) {
    data.paymentProviderId = paymentProviderId;
  }
  let response;

  yield put({ type: PROCESS_INVOICE_INPROGRESS, payload: true });
  if (isPaymentRequired) {
    try {
      if (providerType === 'amaryllis') {
        const tokenizationResult = yield call(submitForm);
        if (!tokenizationResult?._id) {
          yield put({ type: PROCESS_INVOICE_INPROGRESS, payload: false });
        }
        data.paymentMethodId = tokenizationResult._id;
      } else if (providerType === 'stripe') {
        const {
          data: { useNPM: isStripeSCAon },
        } = yield call(
          checkIsV2() ? fetchCross : api.get,
          `${checkIsV2() ? SEND_QUOTE_URL : QUOTES_URL}/${quotesId}`,
        );
        if (isStripeSCAon ?? true) {
          const {
            data: { client_secret: clientSecret },
          } = yield call(checkIsV2() ? getClientSecretV2 : getClientSecret, { accountId, listingId });

          const { err, setupIntent } = yield call(handleCardSetup, {
            clientSecret,
            stripe,
            billingDetails,
          });

          if (!err && setupIntent) {
            data.token = checkIsV2() ? { id: setupIntent.payment_method } : setupIntent.payment_method;
          } else {
            // Stripe.js: failed to handle card setup
            yield put({ type: SHOW_DIALOG, name: PAYMENT_METHOD_FAILED_DIALOG });
            yield put({ type: PROCESS_INVOICE_INPROGRESS, payload: false });
            return;
          }
        } else {
          const { token: stripeToken } = yield call(createToken, {
            stripe,
            billingDetails,
          });
          if (stripeToken) {
            data.token = stripeToken.id;
          } else {
            // Stripe.js: failed to create token
            yield put({ type: SHOW_DIALOG, name: PAYMENT_METHOD_FAILED_DIALOG });
            yield put({ type: PROCESS_INVOICE_INPROGRESS, payload: false });
            return;
          }
        }
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: SHOW_DIALOG, name: PAYMENT_METHOD_FAILED_DIALOG });
      yield put({ type: PROCESS_INVOICE_INPROGRESS, payload: false });
      return;
    }
  }

  if (data.token || checkIsV2()) {
    data.reuse = reusePaymentMethod ?? false;
  }

  try {
    if (checkIsV2()) {
      const payload = {
        encryptedQuoteId: quotesId,
        doneBy: `${booker.firstName} ${booker.lastName}`,
        isRequestToBook,
        conversationId,
        guests,
      };
      if (isSingleQuote) {
        const { ratePlanId, bookerId } = inquiryData;
        data.reservationId = reservation.reservationId;
        payload.createdById = bookerId;
        payload.reservationId = reservation.reservationId;
        payload.ratePlanId = ratePlanId;
      } else {
        payload.commitAll = true;
        payload.createdById = quotes?.meta?.guest?._id;
        payload.ratePlanIdsByReservationId = ratePlanIdsByReservationId;
      }
      if (isPaymentRequired) {
        response = yield call(fetchCross, COMMIT_PAYMENT_URL, {
          method: 'POST',
          body: {
            ...data,
            ...payload,
          },
        });
      } else {
        response = yield call(fetchCross, COMMIT_WITHOUT_PAYMENT_URL, { body: payload, method: 'POST' });
      }
    } else if (inquiryData) {
      data.reservationId = reservation.reservationId;
      const { inquiryId, ratePlanId, bookerId, source } = inquiryData;
      {
        const payload = {
          accountId,
          inquiryId,
          ratePlanId,
          bookerId,
          source,
          conversationId,
          guest: booker,
          isRequestToBook,
        };
        if (isPaymentRequired) {
          response = yield call(api.put, `${QUOTES_URL}/${quotesId}${SAND_WORM_URL}`, {
            ...data,
            ...payload,
          });
        } else {
          response = yield call(api.put, `${QUOTES_URL}/${quotesId}${WITHOUT_PAYMENT_URL}`, {
            reservationId: reservation.reservationId,
            body: {
              ...payload,
            },
          });
        }
      }
    } else {
      data.reservationId = reservation.reservationId;
      if (checkIsV2()) {
        response = yield call(fetchCross, `${SEND_QUOTE_URL}/${quotesId}`, {
          method: 'PUT',
          body: data,
        });
      } else {
        response = yield call(api.put, `${QUOTES_URL}/${quotesId}`, data);
      }
    }
    if (response?.data?.error?.status) {
      throw new Error({ error: response.data });
    }
    if (checkIsV2()) {
      yield history.push(
        setV2URL(
          (isSingleQuote ? SUCCESS_BOOKING_PAGE : SUCCESS_ALL_BOOKING_PAGE)
            .replace(':quotesId', quotesId)
            .replace(':reservationId', reservation?.reservationId),
        ),
      );
    } else if (isSingleQuote) {
      // we need to pass history via action cause push from saga wont trigger rendered
      // due to wrapped into connect route component, see:
      // https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/redux.md
      yield history.push(
        (isPaymentRequired ? SUCCESS_PAID_BOOKING_PAGE : SUCCESS_UNPAID_BOOKING_PAGE).replace(
          ':quotesId',
          quotesId,
        ),
      );
    } else {
      yield history.push(
        (isPaymentRequired ? SUCCESS_PAID_BOOKING_PAGE : SUCCESS_ALL_BOOKING_PAGE).replace(
          ':quotesId',
          quotesId,
        ),
      );
    }
  } catch (error) {
    console.warn(error);
    if (checkIsV2()) {
      yield history.push(setV2URL(COMMIT_ERROR.replace(':quotesId', quotesId)));
    } else {
      yield put({
        type: PROCESS_INVOICE_FAILURE,
        payload: parseErrorResponse(error),
      });
    }
  }
}

function* updateReservation({ body, quoteId }) {
  try {
    let response;
    if (checkIsV2()) {
      response = yield call(fetchCross, COMMIT_WITHOUT_PAYMENT_URL, { body, method: 'POST' });
    } else {
      response = yield call(api.put, `${QUOTES_URL}/${quoteId}${WITHOUT_PAYMENT_URL}`, body);
    }
    return { success: true, response, body };
  } catch (error) {
    return { success: false, response: error, body };
  }
}

function* updateGuestBatchInvoice(action) {
  const params = Object.values(action.payload.body);
  const response = yield all(
    params.map((param) => call(updateReservation, { quoteId: action.payload.quoteId, body: param })),
  );

  const failedResponses = _.filter(response, { success: false });

  if (!_.isEmpty(failedResponses) && _.isEmpty(response)) {
    action.payload.reject({ failures: failedResponses });
  } else {
    action.payload.resolve({ success: response });
  }
}

export default [
  takeOneAndBlock(PROCESS_INVOICE_REQUEST, processBooking),
  takeOneAndBlock(FETCH_INVOICE_DATA_REQUEST, getInvoiceData),
  takeOneAndBlock(UPDATE_GUEST_INVOICE_REQUEST, updateGuestBatchInvoice),
];
