import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import LangSelect from 'components/LangSelect/LangSelect.component';
import styled from 'styled-components';
import { mobile, tablet } from 'constants/css/breakpoints';

import TextField from '@guestyci/foundation/TextField';
import { Col } from '@guestyci/foundation';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import { PaneTag, PaneWrapper, SubTitle } from './Pane.component.styled';

const LangWrapper = styled(LangSelect)`
  top: -80px;
  margin: 0 120px;
  @media ${tablet} {
    margin: 80px 60px;
  }
  @media ${mobile} {
    margin: 80px 20px;
  }
`;

const Pane = ({ Img, subTitle = '', title = '', bottomText = '', isMobile }) => {
  const [, hasLang] = useFeatureToggle('RES-SAQ-lang');
  return (
    <PaneWrapper className="d-flex flex-center">
      <PaneTag className="d-flex flex-1 flex-column flex-center">
        {hasLang && <LangWrapper />}
        <Col align="center" justify="center" className=" p-4">
          <img src={Img} className="max-width-fill pr-6 pl-6" alt="" />
          {title && (
            <TextField variant="h2" className={cn('mb-1 text-center', { 'w-75': isMobile })}>
              {title}
            </TextField>
          )}
          {subTitle && (
            <TextField variant="h4" className={cn('text-center', { 'mb-2': bottomText })}>
              <SubTitle>{subTitle}</SubTitle>
            </TextField>
          )}
          {bottomText && (
            <TextField variant="h5" color="light" className={cn('text-center', { 'mt-20': isMobile })}>
              {bottomText}
            </TextField>
          )}
        </Col>
      </PaneTag>
    </PaneWrapper>
  );
};

Pane.propTypes = {
  Img: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  bottomText: PropTypes.string,
};

export default Pane;
