import { path } from 'ramda';
import { useMemo } from 'react';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

import t from '@guestyci/localize/t.macro';

import ChildrenIcon from '../../assets/house_rules/children.svg';
import PetsIcon from '../../assets/house_rules/pets.svg';
import NoPetsIcon from '../../assets/house_rules/no_pets.svg';
import NoSmokingIcon from '../../assets/house_rules/no_smoking.svg';
import NoPartyIcon from '../../assets/house_rules/no_party.svg';
import QuietHoursIcon from '../../assets/house_rules/quiet_hours.svg';

const convertTime = (str) => {
  if (str) {
    const value = str.toLowerCase();
    try {
      if (value.includes('pm') || value.includes('am')) {
        return format(parse(value, 'hh:mm aaa', new Date()), 'hh:mm aaa');
      }
      if (value) {
        return format(parse(value, 'HH:mm', new Date()), 'hh:mm aaa');
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Failed to parse sting', str);
    }
  }
  return '';
};

export const useHouseRulesData = (data) => {
  const childrenRules = path(['childrenRules'], data);
  const petsAllowed = path(['petsAllowed'], data);
  const smokingAllowed = path(['smokingAllowed'], data);
  const suitableForEvents = path(['suitableForEvents'], data);
  const quietBetween = path(['quietBetween'], data);

  const { suitableForInfants = false, suitableForChildren = false, reason = '' } = childrenRules || {};
  const { enabled: petsEnabled = false, chargeType = '' } = petsAllowed || {};
  const { enabled: smokingEnabled = false } = smokingAllowed || {};
  const { enabled: eventsEnabled = false } = suitableForEvents || {};
  const { enabled: quietBetweenEnabled = false, hours = {} } = quietBetween || {};

  const childrenDescText = useMemo(() => {
    let text = '';

    if (!suitableForChildren && !suitableForInfants) {
      text = t('Not suitable for children and infants');
    }

    if (suitableForChildren && !suitableForInfants) {
      text = t('Not suitable for infants (0-2 years)');
    }

    if (!suitableForChildren && suitableForInfants) {
      text = t('Not suitable for children (2-12 years)');
    }

    if (reason.length > 0) {
      text += ` - ${reason}`;
    }

    return text;
  }, [suitableForChildren, suitableForInfants, reason]);

  return [
    {
      key: 'children',
      title: t('Children'),
      desc: childrenDescText,
      icon: ChildrenIcon,
      show: !suitableForInfants || !suitableForChildren,
    },
    {
      key: 'pets',
      title: petsEnabled ? t('Pets are allowed but charges may be applied') : t('No pets'),
      icon: petsEnabled ? PetsIcon : NoPetsIcon,
      show: !petsEnabled || chargeType === 'petStayCharge',
    },
    {
      key: 'smoking',
      title: t('No smoking'),
      icon: NoSmokingIcon,
      show: !smokingEnabled,
    },
    {
      key: 'parties',
      title: t('No parties or events'),
      icon: NoPartyIcon,
      show: !eventsEnabled,
    },
    {
      key: 'quiet',
      title: t('Quiet hours'),
      desc: quietBetweenEnabled
        ? t('Guests need to be quiet between {start} and {end}', {
            start: convertTime(hours.start),
            end: convertTime(hours.end),
          })
        : '',
      icon: QuietHoursIcon,
      show: quietBetweenEnabled,
    },
  ];
};
