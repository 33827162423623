import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { LanguageProvider } from 'contexts/LanguageContext';
import {
  EXPIRED_PAGE,
  INVOICE_PAGE,
  LISTING_PAGE,
  MAIN_PAGE,
  SUCCESS_UNPAID_BOOKING_PAGE,
  SUCCESS_PAID_BOOKING_PAGE,
  BOOK_ALL_LISTING_PAGE,
  BOOK_ALL_INVOICE_PAGE,
  SUCCESS_ALL_BOOKING_PAGE,
  SUCCESS_BOOKING_PAGE,
} from 'constants/route.constants';
import App from 'App';
import OnBoarding from 'App/OnBoarding';

import { setEnv } from '@guestyci/atomic-design/dist/resource/';
import '@guestyci/foundation/style/styles.css';
import '@guestyci/arc-styles/index.css';

import registerServiceWorker from './registerServiceWorker';
import configureStore, { history } from './store';

const store = configureStore();
const cluster = process.env.REACT_APP_CLUSTER;
setEnv(cluster);

const AppWithLocalization = () => (
  <Route
    path={[
      EXPIRED_PAGE,
      SUCCESS_UNPAID_BOOKING_PAGE,
      SUCCESS_PAID_BOOKING_PAGE,
      SUCCESS_ALL_BOOKING_PAGE,
      SUCCESS_BOOKING_PAGE,
      MAIN_PAGE,
      BOOK_ALL_INVOICE_PAGE,
      BOOK_ALL_LISTING_PAGE,
      LISTING_PAGE,
      INVOICE_PAGE,
    ]}
    render={(props) => (
      <OnBoarding {...props}>
        <App />
      </OnBoarding>
    )}
  />
);

const init = () => {
  const root = createRoot(document.getElementById('root'));
  root.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Router>
          <LanguageProvider>
            <AppWithLocalization />
          </LanguageProvider>
        </Router>
      </ConnectedRouter>
    </Provider>,
  );
};

init();
registerServiceWorker();
