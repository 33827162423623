import React from 'react';
import PropTypes from 'prop-types';

import t from '@guestyci/localize/t.macro';
import TextField from '@guestyci/foundation/TextField';

import { CANCEL_FEE_0 } from '../../InformationStep/translations';

const RateInfo = ({ mealPlans, policy, fee }) => (
  <div className="bg-gray-lighter mb-2 px-3 py-2">
    <ul className="list-group ml-2">
      {!!policy && (
        <li key="cancellation-policy">
          <TextField variant="h7" color="default">
            {t('Cancellation Policy: {policy}', { policy })}
          </TextField>
        </li>
      )}
      {fee !== CANCEL_FEE_0 && (
        <li key="cancellation-fee">
          <TextField variant="h7" color="default">
            {t('Cancellation Fee: {fee}', { fee })}
          </TextField>
        </li>
      )}
      {!!mealPlans?.length && (
        <li key="meal-plans">
          <TextField variant="h7" color="default">
            {t('Meal Plans: {mealPlans}', { mealPlans: mealPlans.join(', ') })}
          </TextField>
        </li>
      )}
    </ul>
  </div>
);

RateInfo.propTypes = {
  ratesCount: PropTypes.number,
  selectedRates: PropTypes.arrayOf(
    PropTypes.shape({
      ratePlanId: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      cancellationPolicy: PropTypes.string,
      cancellationFee: PropTypes.string,
      isCancellationPolicyByChannels: PropTypes.bool,
      cancellationPolicyByChannels: PropTypes.shape(),
      mealPlans: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

export default RateInfo;
