import { checkIsV2 } from 'utils/helpers';

export const ACCOUNTS_URL = '/api/v2/accounts';
export const INVOICE_URL = '/quotes/payment';
export const QUOTES_LISTING_URL = '/quotes/listing';
export const QUOTES_URL = '/quotes';
export const COMMIT_WITHOUT_PAYMENT_URL = '/send-quote/commit/without-payment';
export const COMMIT_PAYMENT_URL = '/send-quote/commit/payment';
export const SEND_QUOTE_URL = '/send-quote';
export const SAND_WORM_URL = '/sand-worm';
export const WITHOUT_PAYMENT_URL = '/without-payment';
// TODO: for testing purposes
// export const STRIPE_SETUP_INTENTS_URL = '/api/v2/stripe/setupIntents';
export const STRIPE_SETUP_INTENTS_URL = '/quotes/payment/token';
export const STRIPE_SETUP_INTENT_URL = '/send-quote/setup-intent';

export const QS_PARSE_CONFIG = { ignoreQueryPrefix: true };

export const getQuotesListingUrl = ({ quotesId, listingId }) =>
  checkIsV2()
    ? `${SEND_QUOTE_URL}/listing/${quotesId}/${listingId}`
    : `${QUOTES_URL}/${quotesId}/listing/${listingId}`;

const env = process.env.REACT_APP_CLUSTER;

export const geBaseUrl = () =>
  checkIsV2()
    ? `https://guest-app${env !== 'production' ? `-${env}` : ''}.guesty.com/api`
    : `https://app${env !== 'production' ? `-${env}` : ''}.guesty.com/api/public`;
