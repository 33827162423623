import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useExpirationDate } from 'hooks/useExpirationDate';
import { getFormattedTimeDifference, noop } from 'utils/helpers';
import { EXPIRED_PAGE } from 'constants/route.constants';

import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro';
import { FixedRaisedButton } from '@guestyci/atomic-design/dist/v2/components';

import {
  ExpirationPart,
  ExpiresTag,
  ExpiresTitle,
  FooterContent,
  // FooterTag,
  TotalPriceTag,
} from './Footer.styled';

const Footer = ({
  expiration,
  history,
  isMobile,
  onClick,
  totalPrice,
  totalPriceCaption,
  isPaymentRequired,
}) => {
  const liveExpiration = useExpirationDate(expiration, () => history.push(EXPIRED_PAGE));

  return (
    <div className="d-flex flex-column">
      {isMobile && expiration && (
        <ExpirationPart className="d-flex align-items-center justify-content-center mb-1 w-100 font-size-lg">
          {t('Offer expires in')}
          &nbsp;
          {getFormattedTimeDifference(liveExpiration)}
        </ExpirationPart>
      )}
      <FooterContent className="d-flex align-items-center justify-content-between w-100">
        {!isMobile && expiration && (
          <div className="d-flex flex-column">
            <ExpiresTitle className="d-flex justify-content-start font-size-lg font-weight-bold">
              {t('Offer expires in')}
            </ExpiresTitle>
            <ExpiresTag className="d-flex justify-content-start font-size-21">
              {getFormattedTimeDifference(liveExpiration)}
            </ExpiresTag>
          </div>
        )}
        <div
          className={cn('d-flex ml-auto align-items-center', {
            'flex-1 justify-content-between': isMobile,
          })}
        >
          <TotalPriceTag className="d-flex align-items-baseline pr-6">
            <TextField className="font-size-lg-xl" color="dark" bold>
              {totalPriceCaption} {totalPrice}
            </TextField>
          </TotalPriceTag>
          <div>
            <FixedRaisedButton
              style={{ width: isMobile ? '160px' : 'auto', minWidth: '160px' }}
              onClick={onClick}
            >
              {isPaymentRequired ? t('Book Now') : t('Request to book')}
            </FixedRaisedButton>
            <TextField className="color-gray font-size-sm pt-1 text-center">
              {t("You won't be charged yet")}
            </TextField>
          </div>
        </div>
      </FooterContent>
    </div>
  );
};

Footer.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

Footer.defaultProps = {
  onClick: noop,
};

export default Footer;
