import t from '@guestyci/localize/t.macro';

export const useErrorMessages = () => {
  const ERROR_MESSAGES = {
    processing_error: t('Please try again or try with another credit card.'),
    expired_card: t('Your credit card has expired, please try with another one.'),
    card_declined: t('Your credit card was declined, please try with another one.'),
    invalid_number: t('Invalid number'),
    incorrect_number: t('Incorrect number'),
    incomplete_number: t('Incorrect number'),
    invalid_expiry_month: t('Invalid expiration date'),
    invalid_expiry_year: t('Invalid expiration date'),
    invalid_cvc: t('Invalid CVV'),
    incorrect_cvc: t('Incorrect CVV'),
    DEFAULT: t('Please try again or try with another credit card.'),
    required: t('Required'),
  };

  return ERROR_MESSAGES;
};
