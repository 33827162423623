import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { errorDialogIntlId } from 'utils/intlIds';
import actions from 'redux/actions';
import { PAYMENT_METHOD_FAILED_DIALOG } from 'constants/dialogs.constants';
import { ReactComponent as FailedImg } from 'assets/failed.svg';

import TextField from '@guestyci/foundation/TextField';
import Dialog, { DialogContent } from '@guestyci/foundation/Dialog';
import { Col } from '@guestyci/foundation/Layout';
import t from '@guestyci/localize/t.macro';

const PaymentMethodFailedDialog = () => {
  const dispatch = useDispatch();
  const showDialog = useSelector((state) => state.dialog.show);
  const isOpen = showDialog === PAYMENT_METHOD_FAILED_DIALOG;

  const titleText = t("Payment method didn't save");
  const messageText = t("Please try again or provide a different payment method.");

  const closeDialog = () => dispatch({ type: actions.HIDE_DIALOG });

  return (
    <Dialog onClose={closeDialog} open={isOpen}>
      <DialogContent className="max-width-500 flex-center">
        <Col align="center">
          <Col spacing={3} className="mb-6">
            <FailedImg />
          </Col>
          <Col spacing={3} className="text-center mb-6">
            <TextField variant="h2" color="dark">
              {titleText}
            </TextField>
          </Col>
          <Col spacing={3} className="text-center">
            <TextField variant="h4" color="default">
              {messageText}
            </TextField>
          </Col>
        </Col>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentMethodFailedDialog;
