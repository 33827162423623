let prefix = '/apps/send-quotes';
if (process.env.REACT_APP_CLUSTER === 'production') {
  prefix = '';
}

export const getURLPrefix = () => prefix;

export const MAIN_PAGE = `${prefix}/:quotesId`;
export const LISTING_PAGE = `${prefix}/:quotesId/listing/:listingId`;
export const INVOICE_PAGE = `${prefix}/:quotesId/listing/:listingId/invoice`;
export const BOOK_ALL_LISTING_PAGE = `${prefix}/:quotesId/all-listings`;
export const ERROR_PAGE_NOT_AVAILABLE = `${prefix}/:quotesId/all-listings/not-available`;
export const ERROR_PAGE_OFFER_EXPIRED = `${prefix}/:quotesId/all-listings/offer-expired`;
export const ERROR_PAGE_TECH_ISSUE = `${prefix}/:quotesId/all-listings/tech-issue`;
export const BOOK_ALL_INVOICE_PAGE = `${prefix}/:quotesId/all-listings/invoice`;
export const EXPIRED_PAGE = `${prefix}/expired`;
export const COMMIT_ERROR = `${prefix}/:quotesId/commit-error`;
export const SUCCESS_PAID_BOOKING_PAGE = `${prefix}/:quotesId/success-paid`;
export const SUCCESS_UNPAID_BOOKING_PAGE = `${prefix}/:quotesId/success-unpaid`;
export const SUCCESS_ALL_BOOKING_PAGE = `${prefix}/:quotesId/all-listings/success-all-booking`;
export const SUCCESS_BOOKING_PAGE = `${prefix}/:quotesId/all-listings/:reservationId/success-booking`;
