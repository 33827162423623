import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TechnicalPage from 'App/pages/TechnicalPage/Technical.page';
import { MOBILE } from 'constants/css/breakpoints';
import ExpiredIcon from 'assets/expired.svg';

import t from '@guestyci/localize/t.macro';

const ExpiredPage = ({ breakpoint }) => {
  const isMobile = breakpoint === MOBILE;

  return (
    <TechnicalPage
      breakpoint={breakpoint}
      Img={ExpiredIcon}
      title={isMobile ? t('You just missed it!') : t('Sorry, you missed it!')}
      subTitle={isMobile ? t('Sorry, but the offer has expired') : t('The offer has expired')}
    />
  );
};

ExpiredPage.propTypes = {
  breakpoint: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  breakpoint: state.layout.breakpoint,
});

export default connect(mapStateToProps)(ExpiredPage);
