import React from 'react';
import styled from 'styled-components';
import { useLanguage } from 'contexts/LanguageContext';
import cn from 'classnames';

import t from '@guestyci/localize/t.macro';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@guestyci/arc';

import { ReactComponent as En } from '../../assets/flags/en.svg';
import { ReactComponent as Fr } from '../../assets/flags/fr.svg';
import { ReactComponent as De } from '../../assets/flags/de.svg';
import { ReactComponent as It } from '../../assets/flags/it.svg';
import { ReactComponent as Pl } from '../../assets/flags/pl.svg';
import { ReactComponent as Es } from '../../assets/flags/es.svg';
import { ReactComponent as Pt } from '../../assets/flags/pt.svg';

const languages = [
  { code: 'en', label: t('English'), flag: <En width={16} /> },
  { code: 'fr', label: t('French'), flag: <Fr width={16} /> },
  { code: 'de', label: t('German'), flag: <De width={16} /> },
  { code: 'it', label: t('Italian'), flag: <It width={16} /> },
  { code: 'pl', label: t('Polish'), flag: <Pl width={16} /> },
  { code: 'pt', label: t('Portuguese'), flag: <Pt width={16} /> },
  { code: 'es', label: t('Spanish'), flag: <Es width={16} /> },
];

const Wrapper = styled.div`
  position: absolute;
  top: 55px;
  left: 0;
  right: 0;
  width: calc(100% - 30px);
  max-width: 1200px;
  display: flex;
  margin: auto;
  justify-content: flex-end;
`;

const LangSelect = ({ className }) => {
  const { language, setLanguage } = useLanguage();

  const handleLanguageChange = (value) => {
    setLanguage(value);
  };

  const currentLanguage = languages.find((lang) => lang.code === language) || languages[0];
  return (
    <Wrapper className={cn(className, 'arc-scope')}>
      <div>
        <Select value={currentLanguage.code} onValueChange={handleLanguageChange}>
          <SelectTrigger className="gst-w-[100px] gst-bg-white">
            <SelectValue>
              <div className="gst-flex gst-items-center gst-gap-2">
                <span>{currentLanguage.flag}</span>
                <span>{currentLanguage.code.toUpperCase()}</span>
              </div>
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {languages.map((lang) => (
                <SelectItem key={lang.code} value={lang.code}>
                  <div className="gst-flex gst-items-center gst-gap-2">
                    <span>{lang.flag}</span>
                    <span>{lang.label}</span>
                  </div>
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
    </Wrapper>
  );
};

export default LangSelect;
